export const cleaningCalendarPermissionsView: string[] = [
  "Admin",
  "Analytics",
  "CleanerAdmin", //This type is depreciated once permission roll out
  "Sales_Manager",
  "COO",
  "Help_Offshore",
  "QAM_L4",
  "Sales_Manager",
  "RED",
  "Service_Manager",
];

export const checkInsPermissionsView: string[] = [
  "Admin",
  "QAM_L4",
  "Sales_Manager",
  "Analytics",
  "COO",
  "Primary",
  "Sales_Offshore",
  "Business_Development", //Add this for Cliff for SVSRelo
  "Help_Offshore",
  "Service_Manager",
];

export const bookingsPermissionsView: string[] = [
  "Admin",
  "Finance_Controller",
  "Offshore",
  "QAM_L4",
  "Sales_Manager",
  "Analytics",
  "RED",
  "COO",
  "Business_Development",
  "Primary",
  "Sales_Offshore",
  "Service_Manager",
];

export const parkingsPermissionsView: string[] = [
  "Admin",
  "Sales_Manager",
  "Analytics",
  "RED",
  "COO",
  "Primary",
  "Sales_Offshore",
  "Help_Offshore",
  "Service_Manager",
];

export const invoicesPermissionsView: string[] = [
  "Admin",
  "Sales_Manager",
  "Analytics",
  "Finance_Controller",
  "COO",
  "Payments_Offshore",
  "Business_Development", //Add this for Cliff for SVSRelo
];

export const unitsPermissionsView: string[] = [
  "Admin",
  "Offshore",
  "Sales_Manager",
  "Analytics",
  "Finance_Controller", //Only Blackouts
  "RED",
  "COO",
  "Help_Offshore",
  "Business_Development", //Add this for Cliff for SVSRelo,
  "Service_Manager",
];

export const contactsPermissionsView: string[] = [
  "Admin",
  "Sales_Manager",
  "Analytics",
  "Finance_Controller",
  "COO",
  "Sales_Offshore",
  "Finance",
  "Business_Development",
  "Payments_Offshore",
];

export const extensionsPermissionsView: string[] = [
  "Admin",
  "Sales_Manager",
  "Analytics",
  "Finance_Controller",
  "COO",
  "Payments_Offshore",
  "Business_Development", //Add this for Cliff for SVSRelo
  "Sales_Offshore",
];

export const reportingPermissionsView: string[] = [
  "Admin",
  "Analytics",
  "Finance_Controller",
  "RED",
  "COO",
  "Payments_Offshore",
  "CEO",
  "Business_Development",
  "Sales_Manager",
];
