import { Component } from "react";
import { AiOutlinePaperClip, AiOutlineFileText } from "react-icons/ai";
import { FaCheck, FaPhoneAlt, FaTimes, FaTrash } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { inputISO } from "../../utils/DateFormat";

import { IoIosArrowBack } from "react-icons/io";
import { BsFillPersonPlusFill } from "react-icons/bs";
import ToggleSwitch from "../ToggleSwitch";
import { TiDelete } from "react-icons/ti";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
// import { truncate } from "fs";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import { useState } from "react";

type Props = {
  title: string;
  handleModalToggle: any;
  onoff: boolean;
  ErrorNote: any;
};

type ModalState = {
  title: string;
  onoff: boolean;
  corporation: string;
  address: string;
  admin_contact: string;
  admin_contact_email: string;
  admin_contact_phone: string;
  finance_contact: string;
  finance_contact_email: string;
  finance_contact_phone: string;
  cleaning_contact: string;
  cleaning_contact_email: string;
  cleaning_contact_phone: string;
  client_manager: string;
  payment_type: string;
  flagged: boolean;
  fin_com_pol: string;
  clean_com_pol: string;
  admin_com_pol: string;
  var_com_pol: boolean;
  invoicing_method: string;
  payment_timing: string;
  ntv: number;
  payment_terms: string;
  first_last: string;

  // Corporate contacts fields
  corp_contact_name: string;
  corp_contact_email: string;
  corp_contact_phone: string;
  corp_admin: boolean;
  corp_finance: boolean;
  corp_cleaning: boolean;
  corp_contact_list: any[];

  charge_deposit: boolean;
  charge_cleaning: boolean;
  charge_credit_fee: boolean;

  //* Documents
  files: Array<File>;
  file_names: Array<string>;
  web_URL: string;
  final_notes: string;

  corporate_notes: string;
  expiry: number;

  requires_initial_payment: boolean;

  city: string;
  province_state: string;
  postal_code: string;
  country: string;
};

class NewCorporate extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      title: this.props.title,
      onoff: this.props.onoff,
      corporation: "",
      address: "",
      admin_contact: "",
      admin_contact_email: "",
      admin_contact_phone: "",
      finance_contact: "",
      finance_contact_email: "",
      finance_contact_phone: "",
      cleaning_contact: "",
      cleaning_contact_email: "",
      cleaning_contact_phone: "",
      client_manager: "Arlene Viana",
      payment_type: "Credit",
      flagged: false,
      fin_com_pol: "None",
      clean_com_pol: "None",
      admin_com_pol: "None",
      var_com_pol: true,
      invoicing_method: "thirty",
      payment_timing: "due_on_creation",
      ntv: 0,
      payment_terms: "Net 30",
      requires_initial_payment: false,
      first_last: "None",

      // Corporate contacts fields
      corp_contact_name: "",
      corp_contact_email: "",
      corp_contact_phone: "",
      corp_admin: false,
      corp_finance: false,
      corp_cleaning: false,
      corp_contact_list: [],

      charge_deposit: false,
      charge_cleaning: false,
      charge_credit_fee: false,
      corporate_notes: "",

      //* Documents
      files: [],
      file_names: [],
      web_URL: "",
      final_notes: "",
      expiry: -1,

      //Address:
      city: "",
      province_state: "",
      postal_code: "",
      country: "",
    };

    this.props.ErrorNote.bind(this);
  }

  determinePaymentTerms = () => {
    switch (this.state.payment_terms) {
      case "Net 60":
        return 60;
      case "Net 30":
        return 30;
      case "Net 15":
        return 15;
      case "Immediate":
        return 0;
    }
  };

  makeCorporate = async () => {
    // Make corporate with form info
    let newCorporate = {
      corporate_name: this.state.corporation,
      client_manager: this.state.client_manager,
      method_of_payment: this.state.payment_type,
      first_last_policy: this.state.first_last,
      deposit_policy: this.state.charge_deposit,
      cleaning_fee: this.state.charge_cleaning,
      credit_fee: this.state.charge_credit_fee,
      communication_type: "online",
      active: true,
      invoicing_method: this.state.invoicing_method,
      payment_timing: this.state.payment_timing,
      notice_to_vacate: this.state.ntv,
      payment_terms: this.determinePaymentTerms(),
      notes: this.state.corporate_notes,
      address: this.state.address,
      finance_communication_policy: this.state.fin_com_pol,
      cleaning_communication_policy: this.state.clean_com_pol,
      admin_communication_policy: this.state.admin_com_pol,
      variable_communication_policy: this.state.var_com_pol,
      expiry: +this.state.expiry,
      requires_initial_payment: this.state.requires_initial_payment,
      city: this.state.city ?? null,
      province_state: this.state.province_state ?? null,
      postal_code: this.state.postal_code ?? null,
      country: this.state.country ?? null,
      invoicing_policy: "Individual",
    };
    console.log(newCorporate);
    if (
      newCorporate.corporate_name &&
      newCorporate.method_of_payment &&
      newCorporate.first_last_policy &&
      newCorporate.communication_type &&
      newCorporate.active &&
      newCorporate.invoicing_method &&
      newCorporate.client_manager &&
      newCorporate.payment_timing
    ) {
      let res = await fetchRequestJSON(
        "POST",
        Constants.API_PATH_CORP_CREATE,
        newCorporate
      );
      return res;
    }
    return null;
    // else {
    //   NotificationHandler(
    //     "Warning",
    //     "Required Fields Missing",
    //     "Please fill out the following fields: corporate name, client manager, method of payment, first last policy, deposit policy, cleaning fee, credit fee, communication type, invoicing policy, notice to vacate, payment terms, and the 3 contact forms"
    //   ); //Types : SUCCESS, ERROR, INFO, WARNING
    // }
  };

  checkContacts = () => {
    if (
      this.state.admin_contact.split(" ")[0] &&
      this.state.admin_contact.split(" ")[1] &&
      this.state.admin_contact_phone &&
      this.state.admin_contact_email &&
      this.state.finance_contact.split(" ")[0] &&
      this.state.finance_contact.split(" ")[1] &&
      this.state.finance_contact_phone &&
      this.state.finance_contact_email &&
      this.state.cleaning_contact.split(" ")[0] &&
      this.state.cleaning_contact.split(" ")[1] &&
      this.state.cleaning_contact_phone &&
      this.state.cleaning_contact_email
    ) {
      return true;
    }
    return false;
  };

  resetFields = () => {
    this.setState({
      title: this.props.title,
      onoff: this.props.onoff,
      corporation: "",
      address: "",
      admin_contact: "",
      admin_contact_email: "",
      admin_contact_phone: "",
      finance_contact: "",
      finance_contact_email: "",
      finance_contact_phone: "",
      cleaning_contact: "",
      cleaning_contact_email: "",
      cleaning_contact_phone: "",
      client_manager: "",
      payment_type: "Credit",
      flagged: false,
      fin_com_pol: "None",
      clean_com_pol: "None",
      admin_com_pol: "None",
      var_com_pol: false,
      invoicing_method: "thirty",
      ntv: 0,
      payment_terms: "Net 60",
      payment_timing: "due_on_creation",
      first_last: "None",

      charge_deposit: false,
      charge_cleaning: false,
      charge_credit_fee: false,
      corporate_notes: "",

      //* Documents
      files: [],
      file_names: [],
      web_URL: "",
      final_notes: "",
      expiry: -1,

      corp_contact_name: "",
      corp_contact_email: "",
      corp_contact_phone: "",
      corp_admin: false,
      corp_finance: false,
      corp_cleaning: false,
      corp_contact_list: [],

      province_state: "",
      postal_code: "",
      country: "",
      city: "",
    });
  };

  makeCorporateContact = async (contact: any, corp_id: number) => {
    let corp_contact_payload = {
      ...contact,
      corporate_id: corp_id,
    };

    let res = await fetchRequestJSON(
      "POST",
      Constants.API_PATH_CORP_CONTACT_CREATE,
      corp_contact_payload
    );
    return res;
  };

  handleClick = async () => {
    // this.props.handleModalToggle();
    let NotificationHandler = this.props.ErrorNote;

    const corp = await this.makeCorporate();

    if (!corp || corp.status === "Error") {
      NotificationHandler(corp.status, corp.status, corp.data); //Types : SUCCESS, ERROR, INFO, WARNING
      return;
    }
    console.log(corp);
    let recentCorp = corp.data.id;
    if (recentCorp) {
      // Make corporate contacts
      const contacts = this.state.corp_contact_list;
      if (contacts.length > 0) {
        for (let i = 0; i < contacts.length; i++) {
          let contact = contacts[i];
          let res = await this.makeCorporateContact(contact, recentCorp);
          if (!res || res.status === "Error") {
            NotificationHandler(res.status, res.status, res.data);
            return;
          }
        }
      }

      NotificationHandler(
        "Success",
        "Success",
        this.state.corporation + " has been created."
      ); //Types : SUCCESS, ERROR, INFO, WARNING

      let update1 = store.getState().corpContactReducer.listUpdate;
      update1();
      let update2 = store.getState().corpContactReducer.corpListUpdate;
      update2();
      this.resetFields();
      this.props.handleModalToggle(this.props.onoff);
    }
  };

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  handleContactSubmit = () => {
    let newContact = {
      first_name: this.state.corp_contact_name.split(" ")[0],
      last_name: this.state.corp_contact_name.split(" ")[1],
      phone_number: this.state.corp_contact_phone,
      email: this.state.corp_contact_email,
      admin: this.state.corp_admin,
      finance: this.state.corp_finance,
      cleaning: this.state.corp_cleaning,
      primary: false,
    };
    this.setState(
      (prevState) => ({
        corp_contact_list: [...prevState.corp_contact_list, newContact],
      }),
      () => console.log(this.state.corp_contact_list)
    );
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: string, state: boolean) => {
    this.setState((prevState) => ({
      ...prevState,
      [id]: !state,
    }));
  };

  handleRemoveContact = (index: number) => {
    this.setState((prevState: any) => ({
      corp_contact_list: prevState.corp_contact_list.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };

  handleRemoveFile = (i: number) => {
    let arNames = this.state.file_names;
    let arFiles = this.state.files;
    arNames.splice(i, 1);
    arFiles.splice(i, 1);

    this.setState({
      files: arFiles,
      file_names: arNames,
    });
  };

  handleFileChange = (event: any) => {
    let fileNames = this.state.file_names;
    fileNames.push(event.target.files[0].name);
    let Files = this.state.files;
    Files.push(event.target.files[0]);

    this.setState({
      files: Files,
      file_names: fileNames,
    });
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    let Files: any = <></>;

    if (this.state.file_names.length) {
      Files = this.state.file_names.map((name: any, index: any) => (
        <span className="px-2 fade-in text-gray-600 py-2 flex flex-row items-center hover:bg-gray-100 cursor-default border-b ">
          <AiOutlineFileText className="text-lg mr-1" />
          {name}{" "}
          <TiDelete
            onClick={() => this.handleRemoveFile(index)}
            className="ml-auto text-2xl  hover:text-gray-400 cursor-pointer"
          />{" "}
        </span>
        // <div className="fade-in flex flex-row bg-gray-200 pl-4 pr-2 py-1 mr-3 rounded-full items-center">
        //   <span className="text-gray-600 text-sm mr-4">{unit}</span>{" "}
        //   <TiDelete  className="text-gray-500 text-lg hover:text-red-400 cursor-pointer" />
        // </div>
      ));
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className={
          "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
        }
      >
        <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4 overflow-y-scroll scroll-hide my-10 border-gray-600 max-w-[65%] py-8 px-10 mx-auto flex flex-col items-start justify-start">
          <BsFillPersonPlusFill className="text-gray-600 text-3xl mb-3 fixed" />

          <span className="text-xl font-bold text-gray-800 text-left mt-10">
            {this.state.title}
          </span>
          <span className="text-xs text-center text-gray-400"></span>
          <form className="w-full">
            <span className="text-green-400 text-xs font-semibold">
              Please Add Detailed Information
            </span>
            <div className="grid grid-cols-1 gap-6 w-full my-5">
              <h1 className="text-green-500 strong">Corporate Summary: </h1>
              <hr></hr>
              <label className="block  ">
                <span className="text-gray-400 text-xs">Corporation Name:</span>
                <input
                  id="corporation"
                  onChange={this.handleInputChange}
                  value={this.state.corporation}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 bordxer-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">Address:</span>
                <input
                  id="address"
                  onChange={this.handleInputChange}
                  value={this.state.address}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>
              <label className="block  ">
                <span className="text-gray-400 text-xs">City: </span>
                <input
                  id="city"
                  onChange={this.handleInputChange}
                  value={this.state.city}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>
              <label className="block  ">
                <span className="text-gray-400 text-xs">
                  Province or State:
                </span>
                <input
                  id="province_state"
                  onChange={this.handleInputChange}
                  value={this.state.province_state}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>
              <label className="block  ">
                <span className="text-gray-400 text-xs">Postal Code:</span>
                <input
                  id="postal_code"
                  onChange={this.handleInputChange}
                  value={this.state.postal_code}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>
              <label className="block  ">
                <span className="text-gray-400 text-xs">Country:</span>
                <input
                  id="country"
                  onChange={this.handleInputChange}
                  value={this.state.country}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>
            </div>

            <div className="grid grid-cols-1 gap-6 w-full my-5">
              <label className="block  ">
                <span className="text-gray-400 text-xs">Client Manager:</span>
                <select
                  id="client_manager"
                  value={this.state.client_manager}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option>Arlene Viana</option>
                  <option>Cliff Norton</option>
                  <option>Matt Regush</option>
                  <option>Tracy Li</option>
                  <option>Steven Argyris </option>
                </select>
              </label>
            </div>

            <span>Initial Corporate Contacts:</span>

            {this.state.corp_contact_list.length > 0 && (
              <ContactList
                data={this.state}
                handleRemoveContact={this.handleRemoveContact}
              />
            )}

            <div className="">
              <CorporateContactForm
                handleState={this.handleInputChange}
                handleToggle={this.handleToggleChange}
                handleSubmit={this.handleContactSubmit}
                data={this.state}
              />
            </div>

            {/* <div className="grid grid-cols-1 gap-4 w-full my-5">
              <ContactCard
                data={this.state}
                contactType={"Admin"}
                stateChange={this.handleInputChange}
              />
              <ContactCard
                data={this.state}
                contactType={"Finance"}
                stateChange={this.handleInputChange}
              />
              <ContactCard
                data={this.state}
                contactType={"Cleaning"}
                stateChange={this.handleInputChange}
              />
            </div> */}

            <div className="grid grid-cols-1 gap-4 w-full my-5">
              {/* <label className="block  ">
                <span className="text-gray-400 text-xs">Payment Method:</span>
                <select
                  id="payment_type"
                  value={this.state.payment_type}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option>Credit</option>
                  <option>Cheque</option>
                  <option>Certified Cheque</option>
                  <option>Wire </option>
                  <option>Cash</option>
                  <option>Direct Deposit</option>
                  <option>PAD</option>
                  <option>Terminal</option>
                  <option>Rent Deductions</option>
                  <option>Other</option>
                  <option>TBD</option>
                </select>
              </label> */}
              <h1>Corporate Contact Polices: </h1>
              <hr />
              <label className="block  ">
                <span className="text-gray-400 text-xs">
                  {" "}
                  Admin Communication Policy:
                </span>
                <select
                  id="admin_com_pol"
                  value={this.state.admin_com_pol}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option>Admin </option>
                  <option>Tenant</option>
                  <option>Both</option>
                  <option>None</option>
                </select>
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">
                  Finance Communication Policy:
                </span>
                <select
                  id="fin_com_pol"
                  value={this.state.fin_com_pol}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option>Finance </option>
                  <option>Tenant</option>
                  <option>Both</option>
                  <option>None</option>
                </select>
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">
                  Cleaning Communication Policy:
                </span>
                <select
                  id="clean_com_pol"
                  value={this.state.clean_com_pol}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option>Cleaning </option>
                  <option>Tenant</option>
                  <option>Both</option>
                  <option>None</option>
                </select>
              </label>
              <h1>Financial Details: </h1>
              <hr />
              <label className="block  ">
                <span className="text-gray-400 text-xs">Invoicing Method:</span>
                <select
                  id="invoicing_method"
                  value={this.state.invoicing_method}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value={"thirty"}>Thirty </option>
                  <option value={"stay_based"}>Stay Based</option>
                  <option value={"weekly"}>Weekly</option>
                  <option value={"monthly"}>Monthly </option>
                </select>
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">Payment Timing:</span>
                <select
                  id="payment_timing"
                  value={this.state.payment_timing}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value={"due_on_creation"}>Due On Creation </option>
                  <option value={"due_on_arrival"}>Due On Arrival</option>
                  <option value={"prepayment"}>Pre-Payment</option>
                </select>
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">Payment Terms:</span>
                <select
                  id="payment_terms"
                  value={this.state.payment_terms}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option>Net 30</option>
                  <option>Immediate</option>
                  {/*No Longer used Net options: */}
                  {/* <option>Net 60 </option> */}
                  {/* <option>Net 15</option> */}
                </select>
              </label>
              <h1>Booking Attributes: </h1>
              <hr />

              <label className="block  ">
                <span className="text-gray-400 text-xs">Notice To Vacate:</span>
                <input
                  id="ntv"
                  onChange={this.handleInputChange}
                  onWheel={(e) => {
                    e.currentTarget.blur();
                  }}
                  value={this.state.ntv}
                  type="number"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="--"
                />
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">
                  Expiry Period (Hours):
                </span>
                <input
                  id="expiry"
                  onChange={this.handleInputChange}
                  onWheel={(e) => {
                    e.currentTarget.blur();
                  }}
                  value={this.state.expiry}
                  type="number"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="-1"
                />
              </label>

              <label className="block  ">
                <span className="text-gray-400 text-xs">
                  First/Last Policy:
                </span>
                <select
                  id="first_last"
                  value={this.state.first_last}
                  onChange={this.handleInputChange}
                  className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option>None </option>
                  <option>First/Last </option>
                  <option>First Only</option>
                  <option>Last Only</option>
                </select>
              </label>
            </div>

            {/* <label className="block  mb-5 mt-2">
              <span className="text-gray-400 text-xs">Additional Notes</span>
              <textarea id="owner_notes" onChange={this.handleInputChange} value={this.state.owner_notes} className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows={3} ></textarea>
            </label> */}

            <div className="grid grid-cols-1 gap-1 w-3/4">
              <div className={" block   my-2  "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace-nowrap"
                    }
                  >
                    Variable Communication Policy
                  </span>
                  <ToggleSwitch
                    id="var_com_pol"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.var_com_pol}
                  />
                </div>
              </div>
              <div className={" block   my-2  "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace-nowrap"
                    }
                  >
                    Payment Required for Access?
                  </span>

                  <ToggleSwitch
                    id="requires_initial_payment"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.requires_initial_payment}
                  />
                </div>
              </div>

              {/* <div className={" block  my-2  "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"
                    }
                  >
                    Charge Cleaning
                  </span>

                  <ToggleSwitch
                    id="charge_cleaning"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.charge_cleaning}
                  />
                </div>
              </div>

              <div className={" block  my-2  "}>
                <div className="flex flex-row items-center justify-between ">
                  <span
                    className={
                      " transition-all duration-600 text-gray-600 text-sm mr-1 whitespace"
                    }
                  >
                    Charge Credit Fee
                  </span>

                  <ToggleSwitch
                    id="charge_credit_fee"
                    handleStateToggle={this.handleToggleChange}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.charge_credit_fee}
                  />
                </div>
              </div> */}
            </div>

            <label className="block  w-full mt-2   ">
              <span className="text-gray-500 text-base ">Files</span>
              <div
                // onDragOver={dragOver}
                // onDragEnter={dragEnter}
                // onDragLeave={dragLeave}
                // onDrop={fileDrop}
                className="form-input border-2 mt-3 cursor-pointer hover:bg-gray-100"
              >
                <span className="text-gray-600 flex flex-row w-full items-center align-center justify-center py-6 ">
                  {" "}
                  <AiOutlinePaperClip className="text-xl text-gray-600 mr-1" />{" "}
                  <span className="text-blue-400 mr-1"> Add file </span>
                </span>
              </div>
              <input
                id="file"
                name="file"
                type="file"
                multiple
                onChange={this.handleFileChange}
                className="hidden mt-1  w-full"
              />
            </label>
            {Files}

            <label className="block  mb-5 mt-3">
              <span className="text-gray-400 text-xs">Additional Notes</span>
              <textarea
                id="corporate_notes"
                onChange={this.handleInputChange}
                value={this.state.corporate_notes}
                className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                rows={3}
              ></textarea>
            </label>

            {/* <div className="flex flex-row justify-start w-full mt-6">
              <button
                type="button"
                onClick={() => this.handleClick()}
                className="transition  duration-200 w-full hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
              >
                Add Corporate
              </button>
            </div> */}
          </form>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(NewCorporate);

const CorporateContactForm = (props: any) => {
  return (
    <div className="rounded-sm border border-gray-100 p-4 text-xs">
      <div>
        <span>Name: </span>
        <input
          type={"text"}
          className="text-xs border-0 my-2 placeholder-gray-200 focus:ring-0 w-full"
          placeholder="Enter name"
          onChange={props.handleState}
          id="corp_contact_name"
        />
      </div>
      <div>
        <span>Email: </span>
        <input
          type={"text"}
          className="text-xs border-0 my-2 placeholder-gray-200 focus:ring-0 w-full"
          placeholder="Enter email"
          onChange={props.handleState}
          id="corp_contact_email"
        />
      </div>
      <div>
        <span>Phone Number: </span>
        <input
          type={"text"}
          className="text-xs border-0 my-2 placeholder-gray-200 focus:ring-0 w-full"
          placeholder="Enter phone number"
          onChange={props.handleState}
          id="corp_contact_phone"
        />
      </div>
      <div className="flex flex-row items-center mb-4 space-x-6">
        <span>
          Admin
          <input
            type="checkbox"
            checked={props.data.corp_admin}
            onChange={() =>
              props.handleToggle("corp_admin", props.data.corp_admin)
            }
            className="m-1 focus:ring-0 rounded-sm border-2 border-gray-500"
            id="corp_admin"
          />
        </span>
        <span>
          Finance
          <input
            type="checkbox"
            checked={props.data.corp_finance}
            onChange={() =>
              props.handleToggle("corp_finance", props.data.corp_finance)
            }
            className="m-1 focus:ring-0 rounded-sm border-2 border-gray-500"
            id="corp_finance"
          />
        </span>
        <span>
          Cleaning
          <input
            type="checkbox"
            checked={props.data.corp_cleaning}
            onChange={() =>
              props.handleToggle("corp_cleaning", props.data.corp_cleaning)
            }
            className="m-1 focus:ring-0 rounded-sm border-2 border-gray-500"
            id="corp_cleaning"
          />
        </span>
      </div>
      <div>
        <button
          type="button"
          onClick={() => props.handleSubmit()}
          className="rounded-md bg-blue-400 hover:bg-blue-500 active:bg-blue-600 text-white px-4 py-2"
        >
          Add Contact
        </button>
      </div>
    </div>
  );
};

const ContactList = (props: any) => {
  return (
    <div className="rounded-sm border border-gray-100 p-4 text-xs my-2">
      <span className="text-xs font-semibold">Contact List:</span>
      <div className="min-w-full sm:min-w-0 grid grid-cols-1 gap-4 w-full my-5">
        {props.data.corp_contact_list.map((contact: any, index: number) => (
          <div
            key={index}
            className="overflow-x-auto flex flex-row w-full items-center justify-between bg-gray-50 p-3 rounded-md border border-gray-200 min-w-[600px] sm:min-w-full"
          >
            <div className="flex items-center space-x-3 w-[55%] min-w-[200px]">
              <span className="font-medium text-gray-700 text-md truncate">
                {contact.first_name} {contact.last_name}
              </span>
              <span className="text-gray-400 text-xs truncate">
                {contact.email}
              </span>
              <span className="flex flex-row items-center text-gray-400 text-xs truncate">
                <FaPhoneAlt className="text-gray-500 mr-1" />
                {contact.phone_number}
              </span>
            </div>

            <div className="flex items-center space-x-4 w-[25%] min-w-[180px] justify-center">
              <span className="flex items-center text-xs">
                Admin:{" "}
                {contact.admin ? (
                  <FaCheck className="text-green-500 ml-1" />
                ) : (
                  <FaTimes className="text-red-500 ml-1" />
                )}
              </span>
              <span className="flex items-center text-xs">
                Finance:{" "}
                {contact.finance ? (
                  <FaCheck className="text-green-500 ml-1" />
                ) : (
                  <FaTimes className="text-red-500 ml-1" />
                )}
              </span>
              <span className="flex items-center text-xs">
                Cleaning:{" "}
                {contact.cleaning ? (
                  <FaCheck className="text-green-500 ml-1" />
                ) : (
                  <FaTimes className="text-red-500 ml-1" />
                )}
              </span>
            </div>

            <button
              className="ml-4 text-red-500 hover:text-red-700 w-[10%] min-w-[50px] flex justify-center"
              onClick={() => props.handleRemoveContact(index)}
              type="button"
            >
              <FaTrash />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export function ContactCard(props: any) {
  var id = props.contactType.toLowerCase();
  let name;
  let email;
  let phone;

  if (props.contactType === "Admin") {
    name = props.data.admin_contact;
    email = props.data.admin_contact_email;
    phone = props.data.admin_contact_phone;
  }
  if (props.contactType === "Finance") {
    name = props.data.finance_contact;
    email = props.data.finance_contact_email;
    phone = props.data.finance_contact_phone;
  }
  if (props.contactType === "Cleaning") {
    name = props.data.cleaning_contact;
    email = props.data.cleaning_contact_email;
    phone = props.data.cleaning_contact_phone;
  }

  return (
    <div className="flex flex-col px-4 py-3 h-auto border-2 rounded-sm border-solid border-gray-100 ">
      <div className="flex flex-row w-full  items-center">
        <span className="font-medium text-gray-700 text-md pb-1 ">
          {props.contactType} -
          <input
            id={id + "_contact"}
            type="text"
            className=" transition-all duration-200 font-medium text-gray border-0 p-0  pl-1 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
            value={name}
            onChange={props.stateChange}
            placeholder="Click To Edit Name"
          />
        </span>
      </div>

      <span className="font-regular text-gray-400 text-2xs pb-4">
        Corporate - {props.data.corporation}
      </span>
      <input
        id={id + "_contact_email"}
        type="text"
        className=" font-regular text-gray-400 text-xs transition-all duration-200 border-0 p-0 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
        value={email}
        onChange={props.stateChange}
        placeholder="Click To Edit Email"
      />
      <span className="flex flex-row items-center font-regular text-gray-400 text-xs ">
        <i className="text-gray-500 mr-1 ">
          <FaPhoneAlt />
        </i>
        <input
          id={id + "_contact_phone"}
          type="text"
          className=" font-regular text-gray-400 text-xs transition-all duration-200 border-0 p-0 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200"
          value={phone}
          onChange={props.stateChange}
          placeholder="Click To Edit Phone"
        />
      </span>
    </div>
  );
}
