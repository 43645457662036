import React, { Component } from "react";
import BreadCrumb from "../../BreadCrumb";
import EditOptionPanel from "../../EditOptionPanel";
import { MdPlaylistAdd } from "react-icons/md";
import { AiOutlineFall } from "react-icons/ai";
import TabList from "../../TabList";
import { Link, Router, useRouteMatch } from "react-router-dom";
import Label from "../../LabelWrap";
import StatsBar from "../../StatsBar";
import AgreementModal from "../../AgreementModal";
import PaymentsQuickView from "./PaymentsQuickView";
import InvoiceQuickView from "./InvoiceQuickView";
import {
  formatFullDate,
  formatDay,
  formatStringDate,
} from "../../../utils/DateFormat";
import { RiUser3Fill } from "react-icons/ri";
import UnitBubble from "../../UnitBubble";
import Tooltip from "../../Tooltip";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { PieChart } from "react-minimal-pie-chart";
import { GiExpand } from "react-icons/gi";
import FinancePie from "./PieChart";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import store from "../../../redux/store";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { MdCheckCircle, MdCancel } from "react-icons/md";
import ConfirmModal from "./ConfirmModal";
import { idText } from "typescript";
import DashBar from "../DashBar";
import {
  billingType,
  bookingType,
  tenantType,
  unitType,
  invoiceType,
  consolidatedInvoiceType,
} from "../../../models/modelList";
import ToggleSwitch from "../../ToggleSwitch";
import ConsolidatedQuickView from "./ConsolidatedQuickView";
import { request } from "../../../models/modelList";
import { formatAmountForDisplay } from "../../../utils/DollarFormat";
// import { Extensions } from "../Sales/SalesDash";
import { GetAppStage, getUserList } from "../../../api-requests/api-gateway";
import { withHooksHOC } from "../../Notifications/NotificationProvider";

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

type Props = {
  userData: any;
  ErrorNote: any;
};

type State = {
  booking_id: number;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  tenant_email: string;
  booking_total: string;
  toggleQuickView: boolean;
  toggleInvoice: boolean;
  user_name: string;
  check_in: Date;
  confirmed: boolean;
  flight_number: string;
  invoices: invoiceType[];
  bookings: bookingType[];
  tenants: tenantType[];
  units: unitType[];
  billings: billingType[];
  extensions: any[] /* TODO: Is there an extensions type? */;
  toggleConfirm: boolean;
  confirm_id: number;
  consolidated: boolean;
  consolidatedInvoices: consolidatedInvoiceType[];
  toggleConsolidated: boolean;
  currentMonth: number;
  currentYear: number;
  stats: StatBubble;
  enable_month: boolean;
  filter_month: string;
  users: any[];
  staywell: boolean;
};

type StatBubble = {
  accounts_receivable: number;
  accounts_payable: number;
  monthly_chargebacks: number;
  bookings_uncovered: number;
};

class FinDash extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      booking_id: 1234,
      check_in: new Date(2020, 7, 8, 4, 20),
      check_out: "March 12th 2021",
      corporate_id: "Sky View Suites",
      tenant_name: "Riley Densmore",
      tenant_email: "rileydensmore@gmail.com",
      booking_total: "84",
      toggleInvoice: false,
      toggleQuickView: false,
      user_name: "Matthew",
      confirmed: false,
      flight_number: "AC1130",
      invoices: [],
      bookings: [],
      tenants: [],
      units: [],
      billings: [],
      extensions: [],
      toggleConfirm: false,
      confirm_id: -1,
      consolidated: false,
      consolidatedInvoices: [],
      toggleConsolidated: false,
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      stats: {
        accounts_receivable: 0,
        accounts_payable: 0,
        monthly_chargebacks: 0,
        bookings_uncovered: 0,
      },
      enable_month: false,
      filter_month: "",
      users: [],
      staywell: false,
    };

    this.handleInvoiceView = this.handleInvoiceView.bind(this);
  }

  handleQuickView = (state?: any) => {
    if (state && this.state.toggleQuickView === false) {
      this.setState({ toggleQuickView: true });
    } else {
      this.setState({ toggleQuickView: false });
    }
  };

  handleConsolidatedSwitch = () => {
    if (this.state.consolidated) {
      this.setState({ consolidated: false });
    } else {
      this.setState({ consolidated: true });
    }
  };

  handleConfirmModal = (id: number) => {
    this.setState({
      toggleConfirm: !this.state.toggleConfirm,
      confirm_id: id,
    });
  };

  openInvQuickView = (id: number) => {
    store.dispatch({ type: "invoiceIdTransfer", id: id });
    this.handleInvoiceView(false);
  };

  handleInvoiceView = (state: any) => {
    if (state === false) {
      this.setState({ toggleInvoice: true });
    } else {
      this.setState({ toggleInvoice: false });
    }
  };

  handleDownload = async (invoiceId: number) => {
    let response = await fetchGetJSON(
      `${Constants.API_PATH_FILES_LIST}?id=${invoiceId}&category=invoice`
    );

    if (response.status === "Error") {
      alert("Error when retrieving files, please contact dev team");
      return;
    } else if (response.data.Contents.length === 0) {
      alert("No File to Download");
      return;
    }
    const request = await fetchGetJSON(
      `${Constants.API_PATH_FILES_DOWNLOAD}?key=${encodeURIComponent(
        response.data.Contents[0].Key
      )}`
      // { key: key }
    );
    // const request = await fetchRequestJSON(
    //   "POST",
    //   Constants.API_PATH_FILES_DOWNLOAD,
    //   { key: contentsInfo.data.Contents[0].Key }
    // );
    window.open(request.data);
  };

  handleDecline = async (invoiceId: number) => {
    let res: any;
    let NotificationHandler = this.props.ErrorNote;
    res = await fetchRequestJSON(
      "DELETE",
      `${Constants.API_PATH_DECLINE_INVOICE}${invoiceId}`,
      {}
    );
    console.log(res.status);
    if (res.status == "Success") {
      NotificationHandler(
        "Success",
        "Success, invoice declined!",
        "Please refresh the page to see the changes."
      );
    }
  };

  handleConsolidatedDownload = async (consolidatedId: number) => {
    // let allInvoices = await fetchRequestJSON(
    //   "POST",
    //   Constants.API_PATH_CONSOLIDATED_LINKED,
    //   { consolidated_id: consolidatedId }
    // );
    // if (!allInvoices.data[0]){
    //   alert("No Image to Download")
    //   return
    // }
    // for(let i = 0; i < allInvoices.data.length; i++){
    //   this.handleDownload(allInvoices.data[i].id)
    // }
  };

  openConQuickView = (id: number) => {
    store.dispatch({ type: "consolidatedInvoiceIdTransfer", id: id });
    this.handleConsolidatedView(false);
  };

  handleConsolidatedView = (state: any) => {
    if (state === false) {
      this.setState({ toggleConsolidated: true });
    } else {
      this.setState({ toggleConsolidated: false });
    }
  };

  handleFilterSwitch = () => {
    this.setState({ enable_month: !this.state.enable_month });
  };

  componentDidMount = async () => {
    this.setState({ staywell: GetAppStage().name1 === "StayWell" });
    let stats: request<StatBubble> = await fetchGetJSON(
      Constants.API_PATH_FINANCE_DASHBOARD
    );
    this.setState({
      stats: stats.data,
    });
    let userList = await getUserList();
    this.setState({
      users: userList.Users,
    });
    store.dispatch({ type: "invoiceListUpdate", listUpdate: this.updateList });
    await this.updateList();
  };

  updateList = async (bookingId?: string) => {
    let allInvoices = await fetchGetJSON(
      `${Constants.API_PATH_INVOICE_APPENDING}`
    );

    if (allInvoices.statusCode === 404) {
      return;
    }
    console.log(allInvoices);

    allInvoices.data.invoices.sort(
      (a: invoiceType, b: invoiceType) => b.id - a.id
    );
    // let allBookings = await fetchGetJSON(Constants.API_PATH_BOOKING_GET_ALL);
    // let allTenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);
    // let allUnits = await fetchGetJSON(`${Constants.API_PATH_UNIT_GET_ALL}`);
    let allBillings = await fetchGetJSON(Constants.API_PATH_BILLING_GET_ALL);
    this.setState({
      invoices: allInvoices.data.invoices,
      bookings: allInvoices.data.bookings,
      tenants: allInvoices.data.tenants,
      units: allInvoices.data.units,
      billings: allBillings.data,
      // consolidatedInvoices: allConsolidated.data
    });
    console.log(this.state.invoices);
    console.log(allInvoices.data);
  };

  async acceptExtension(id: number) {
    //this route will communicate with the back-end in order to accept the current extension
    let res = await fetchRequestJSON(
      "PUT",
      Constants.API_PATH_EXTENSION_CONFIRM,
      { id: id }
    );
    console.log(res);
    this.updateList();
  }

  async declineExtension(id: number) {
    let res = await fetchRequestJSON(
      "DELETE",
      `${Constants.API_PATH_EXTENSION_REJECT}/${id}`,
      { id: id }
    );
    console.log(res);
    this.updateList();
  }

  render() {
    let title = "Agreements";
    let modalContent =
      "Select the properties that will be included in the agreement";
    let today = new Date();
    let time = today.getHours();
    let currentMonth = new Intl.DateTimeFormat("en", { month: "long" }).format(
      today
    );
    let dayTime = "";
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (time >= 0 && time < 12) {
      dayTime = "Morning";
    } else if (time >= 12 && time < 17) {
      dayTime = "Afternoon";
    } else {
      dayTime = "Evening";
    }

    let invoiceText = "";
    let secondColumn = "Unit";
    let thirdColumn = "Tenant";
    let viewInvoice = this.openInvQuickView;
    let confirmTitle = "Confirm Payment";

    // // if(this.state.consolidated) {
    //   invoiceText = "Consolidated"
    //   secondColumn = "Due Date";
    //   thirdColumn = "Amount";
    //   viewInvoice = this.openConQuickView;
    //   confirmTitle = "Consolidated Confirm Payment";

    // } else {
    // //   invoiceText = "Regular Invoices"
    // // }

    return (
      <div className=" font-display bg-f9 h-full lg:h-screen  lg:rounded-tl-lg pt-3 lg:pt-0 ">
        <InvoiceQuickView
          handleModalToggle={this.handleInvoiceView}
          onoff={this.state.toggleInvoice}
          info={undefined}
        />
        <PaymentsQuickView
          handleModalToggle={this.handleQuickView}
          onoff={this.state.toggleQuickView}
          InvoiceQuickView={this.handleInvoiceView}
        />
        <ConfirmModal
          title={confirmTitle}
          handleModalToggle={this.handleConfirmModal}
          onoff={this.state.toggleConfirm}
          id={this.state.confirm_id}
        />

        {/* <BreadCrumb rootPage="Bookings" sideButton="agreement" subPage="" buttonAction={this.handleAgreement}/> */}
        <div className="fade-in px-4 lg:px-10  ">
          <div className=" flex flex-row justify-between font-circular pl-1 w-full pt-16 lg:pt-6 pb-4">
            <DashBar placeholder={""} />

            <div className="lg:flex flex-col hidden ">
              <span className="text-gray-900 font-medium">
                Finance Dashboard
              </span>
              <span className=" text-right text-sm text-gray-400 font-sofia">
                {formatFullDate(today)}
              </span>
            </div>
          </div>

          <div className="  w-full">
            <div className="pt-0  grid  grid-cols-1 sm:grid-cols-6 ">
              <div className=" col-start-1 col-end-5 flex flex-col">
                <span className=" pl-1 text-4xl leading-tight font-medium text-gray-500">
                  Good {dayTime},{" "}
                  <span className="font-thin text-gray-700">
                    {this.props.userData.user_f_name}
                  </span>
                </span>

                <span className=" ml-1 font-thin text-gray-600 font-sofia">
                  Let&apos;s Get To Work Today
                </span>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 w-full  my-5  ">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4  px-1 py-0 mb-4">
                <div className=" appear flex flex-col bg-white shadow-md   py-5 px-5 rounded-lg">
                  <img className="w-8 opacity-40" src="/icons/invoice.svg" />
                  <span className="text-purple-800  text-base pt-2">
                    Accounts Receivable
                  </span>
                  <span className="text-gray-600 font-medium  pt-1 ">
                    {formatAmountForDisplay(
                      this.state.stats.accounts_receivable,
                      "CAD",
                      false
                    )}
                  </span>
                </div>

                <div className=" appear flex flex-col bg-white  shadow-md   py-5 px-5 rounded-lg ">
                  <img className="w-8 opacity-40" src="/icons/invoice-2.svg" />
                  <span className="text-purple-800 text-base pt-2">
                    Accounts Payable
                  </span>
                  <span className="text-gray-600 font-medium  pt-1 ">
                    {formatAmountForDisplay(
                      this.state.stats.accounts_payable,
                      "CAD",
                      false
                    )}
                  </span>
                </div>

                <div className=" appear flex flex-col bg-white   shadow-md  py-5 px-5 rounded-lg">
                  <img className="w-8 opacity-40" src="/icons/charge-2.svg" />
                  <span className="text-purple-800  text-base pt-2">
                    {currentMonth} Charge Backs
                  </span>
                  <span className="text-gray-600 font-medium  pt-1 ">
                    {formatAmountForDisplay(
                      this.state.stats.monthly_chargebacks,
                      "CAD",
                      false
                    )}
                  </span>
                </div>

                <div className=" appear flex flex-col bg-white   shadow-md   py-5 px-5 rounded-lg">
                  <img className="w-8 opacity-40" src="/icons/invoice.svg" />
                  <span className="text-purple-800 text-base pt-2">
                    Bookings Uncovered
                  </span>
                  <span className="text-gray-600 font-medium  pt-1 ">
                    {formatAmountForDisplay(
                      this.state.stats.bookings_uncovered,
                      "CAD",
                      false
                    )}
                  </span>
                </div>
              </div>

              <div className=" grid grid-cols-1 lg:grid-cols-4 gap-4  px-1 py-0    ">
                <div className="mt-2">
                  <span className="text-gray-700 font-medium text-xl">
                    Payable / Receivable :
                  </span>
                  <div className="  rounded-lg h-96   px-5 mt-4 pt-5 pb-1">
                    <FinancePie
                      ap={this.state.stats.accounts_payable}
                      ar={this.state.stats.accounts_receivable}
                    />
                  </div>
                </div>

                {this.state.staywell && (
                  <div className="  col-span-3">
                    <div className="flex flex-row justify-between w-full  items-end">
                      <span className=" pl-1 text-gray-700 font-medium text-xl">
                        Payments To Approve:
                      </span>
                    </div>

                    <div className="flex flex-row items-end">
                      <span className=" pl-1 text-gray-700 font-medium text-m mt-6">
                        {invoiceText}
                      </span>

                      {/* {/* <div className="pl-4">
                      <ToggleSwitch
                        id="consolidated"
                        handleStateToggle={this.handleConsolidatedSwitch}
                        size="lg"
                        onoff={this.state.consolidated}
                      />
                    </div> */}

                      <span className=" pl-4 text-gray-700 font-medium text-m mt-6">
                        Filter By Month
                      </span>

                      <div className="pl-4">
                        <ToggleSwitch
                          id="monthFilter"
                          handleStateToggle={this.handleFilterSwitch}
                          size="lg"
                          onoff={this.state.enable_month}
                        />
                      </div>

                      {this.state.enable_month && (
                        <>
                          <button
                            className="bg-white rounded-full shadow-md h-6 w-6 ml-4"
                            onClick={() => {
                              let temp = this.state.currentMonth - 1;
                              if (temp < 0) {
                                temp = 11;
                              }
                              this.setState({ currentMonth: temp });
                            }}
                          >
                            <IoIosArrowBack className="pl-1" />
                          </button>

                          <span className="px-4 text-gray-500">
                            {month[this.state.currentMonth]}
                          </span>
                          <button
                            className="bg-white rounded-full shadow-md h-6 w-6 flex flex-row items-center justify-center align-center"
                            onClick={() => {
                              let temp = this.state.currentMonth + 1;
                              if (temp > 11) {
                                temp = 0;
                              }
                              this.setState({ currentMonth: temp });
                            }}
                          >
                            <IoIosArrowForward className="text-sm" />
                          </button>

                          <button
                            className="bg-white rounded-full shadow-md h-6 w-6 ml-4"
                            onClick={() => {
                              this.setState({
                                currentYear: this.state.currentYear - 1,
                              });
                            }}
                          >
                            <IoIosArrowBack className="pl-1" />
                          </button>
                          <span className="px-4 text-gray-500">
                            {this.state.currentYear}
                          </span>
                          <button
                            className="bg-white rounded-full shadow-md h-6 w-6 flex flex-row items-center justify-center align-center"
                            onClick={() => {
                              this.setState({
                                currentYear: this.state.currentYear + 1,
                              });
                            }}
                          >
                            <IoIosArrowForward className="text-sm" />
                          </button>
                        </>
                      )}
                    </div>

                    <div className=" my-3 h-96 w-auto overflow-y-auto shadow-md rounded-lg  w-full scroll-hide ">
                      <table
                        style={{ borderSpacing: "0" }}
                        className="border-separate  relative w-full table-auto bg-white     "
                      >
                        <thead className="border-b border-gray-400 ">
                          <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
                            <th className="sticky top-0  py-4 pl-5 font-thin border-b border-gray-400  ">
                              ID
                            </th>
                            <th className="sticky top-0   py-3 font-thin border-b border-gray-400 ">
                              {secondColumn}
                            </th>
                            <th className=" sticky top-0  py-3 font-thin border-b border-gray-400 ">
                              {thirdColumn}
                            </th>
                            <th className=" sticky top-0  py-3 font-thin border-b border-gray-400 ">
                              Marked By
                            </th>
                            <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 ">
                              Payment Method
                            </th>
                            <th className=" sticky top-0  py-3 font-thin border-b border-gray-400 "></th>
                            <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 "></th>
                            <th className=" sticky top-0   py-3 font-thin border-b border-gray-400 "></th>
                            <th className="sticky top-0  text-right py-3 font-thin border-b border-gray-400  ">
                              <button
                                className="mr-4"
                                onClick={this.handleQuickView}
                              >
                                <GiExpand className="text-xl text-right" />
                              </button>{" "}
                            </th>
                            <th className=" sticky top-0 font-thin border-b border-gray-400 "></th>
                          </tr>
                        </thead>

                        <tbody className="divide-y">
                          <PaymentsToApprove
                            invoiceList={this.state.invoices}
                            bookingList={this.state.bookings}
                            tenantList={this.state.tenants}
                            userList={this.state.users}
                            unitList={this.state.units}
                            billingList={this.state.billings}
                            viewInvoice={viewInvoice}
                            handleConfirm={this.handleConfirmModal}
                            handleDownload={this.handleDownload}
                            handleDecline={this.handleDecline}
                            handleConsolidatedDownload={
                              this.handleConsolidatedDownload
                            }
                            consolidated={this.state.consolidated}
                            // consolidatedList={this.state.consolidatedInvoices}
                            filter={{
                              enable: this.state.enable_month,
                              month: this.state.currentMonth,
                              year: this.state.currentYear,
                            }}
                            consolidatedList={[]}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
              {/* 
              <div className=" grid grid-cols-1 gap-4  px-1 py-0    ">
                <div className="  ">
                  <div className="flex flex-row justify-between w-full  items-end">
                    <span className=" pl-1 text-gray-700 font-medium text-xl">
                      Tentative Extensions:
                    </span>
                  </div>

                  <div className=" my-3 h-96  overflow-y-auto shadow-md rounded-lg  w-full scroll-hide ">
                    <table
                      style={{ borderSpacing: "0" }}
                      className="border-separate  relative w-full table-auto bg-white     "
                    >
                      <thead className="border-b border-gray-400 ">
                      <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
                        <th className="sticky top-0  py-4 pl-5  font-thin border-b border-gray-400  ">
                          Booking ID
                        </th>
                        <th className="sticky top-0  py-4 pl-5  font-thin border-b border-gray-400  ">
                          Tenant
                        </th>
                        <th className="sticky top-0  py-4 pl-5  font-thin border-b border-gray-400  ">
                          Unit
                        </th>
                        <th className="sticky top-0   py-3 font-thin border-b border-gray-400 ">
                          Check Out
                        </th>
                        <th className="sticky top-0 text-right py-3 font-thin border-b border-gray-400 "></th>
                        <th className="sticky top-0   py-3 font-thin border-b border-gray-400 ">
                          Extension To{" "}
                        </th>
                        <th className="sticky top-0 text-right py-3 font-thin border-b border-gray-400 bg-white z-50 "></th>
                      </tr>
                      </thead>

                      <tbody className="divide-y">
                      <Extensions
                        extensionList={this.state.extensions}
                        accept={this.acceptExtension}
                        decline={this.declineExtension}
                        updateList={this.updateList}
                      />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          {/* Heading Info Area  */}
        </div>
      </div>
    );
  }
}

export default withHooksHOC(FinDash);

type PTAProps = {
  consolidatedList: consolidatedInvoiceType[];
  invoiceList: invoiceType[];
  bookingList: bookingType[];
  billingList: billingType[];
  userList: any[];
  unitList: unitType[];
  tenantList: tenantType[];
  viewInvoice: any;
  handleDownload: any;
  handleDecline: any;
  handleConsolidatedDownload: any;
  handleConfirm: any;
  consolidated: boolean;
  length?: number;
  filter: {
    enable: boolean;
    month: number;
    year: number;
  };
};

export function PaymentsToApprove(props: PTAProps) {
  let rows = [];
  let Payments = <></>;
  let altBg = "";

  let sorted = props.invoiceList;
  let tenants = props.tenantList;
  let bookings = props.bookingList;
  let billings = props.billingList;
  let users = props.userList;
  let units = props.unitList;
  // sorted = sorted.filter((invoice: invoiceType) => invoice.paid);
  // sorted = sorted.filter((invoice: invoiceType) => !invoice.confirmed_by);

  if (props.filter.enable) {
    sorted = sorted.filter((invoice: invoiceType) => {
      let date = new Date(invoice.due);
      if (
        date.getMonth() == props.filter.month &&
        date.getFullYear() == props.filter.year
      ) {
        return true;
      }
      return false;
    });
  }
  function findUser(index: number) {
    let marked_by: any;
    let user = users.find((user) => user.Username == sorted[index].marked_by);
    if (user) {
      let name = user.Attributes.find((attr: any) => attr.Name == "name");
      if (name) {
        marked_by = name.Value;
      }
    }
    return marked_by;
  }

  function consolidatedInvoiceTableCreation() {
    let sortedConsolidated = props.consolidatedList.filter(
      (consol: consolidatedInvoiceType) => consol.paid
    );
    sortedConsolidated = sortedConsolidated.filter(
      (consol: consolidatedInvoiceType) => !consol.confirmed_by
    );

    let rows = [];
    for (var i = 0; i < sortedConsolidated.length; i++) {
      if (i % 2 != 1) {
        altBg = "bg-gray-50";
      } else {
        altBg = "bg-white";
      }
      let consolidatedId = sortedConsolidated[i].id;

      //when linking to back end: remember to switch the key to the unique invoice id from the backend
      let marked_by = findUser(i);

      Payments = (
        <tr
          key={i.toString()}
          className={
            altBg + " w-full  hover:bg-gray-100 h-12 border-b border-gray-400  "
          }
        >
          <td
            onClick={() => props.viewInvoice(consolidatedId)}
            className="py-4 pl-5 text-purple-400 font-thin "
          >
            {consolidatedId}
          </td>
          <td onClick={() => props.viewInvoice(consolidatedId)} className=" ">
            {formatStringDate(sortedConsolidated[i].due)}
          </td>
          <td onClick={() => props.viewInvoice(consolidatedId)}>
            {sortedConsolidated[i].amount}{" "}
          </td>
          <td onClick={() => props.viewInvoice(consolidatedId)}>{marked_by}</td>
          <td onClick={() => props.viewInvoice(consolidatedId)}>
            {sortedConsolidated[i].payment_method_invoice}{" "}
          </td>
          <td className="">
            <button
              onClick={() => {
                props.handleConfirm(consolidatedId);
              }}
            >
              <MdCheckCircle className="text-3xl hover:opacity-50 cursor-pointer text-green-600"></MdCheckCircle>
            </button>
          </td>
          <td className=" text-right ">
            <button
              onClick={() => props.handleConsolidatedDownload(consolidatedId)}
              className=" h-8 w-auto mr-3 px-2 border border-gray-300 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white "
            >
              Download
            </button>
          </td>
        </tr>
      );

      rows.push(Payments);
    }
    return rows;
  }

  function regularInvoiceTableCreation() {
    let rows = [];
    for (var i = 0; i < sorted.length; i++) {
      if (i % 2 != 1) {
        altBg = "bg-gray-50";
      } else {
        altBg = "bg-white";
      }
      let invoiceId = sorted[i].id;
      let bookingId = sorted[i].booking_id;
      let booking = bookings.find((book: bookingType) => book.id == bookingId);
      let billing = billings.find(
        (bill: billingType) => bill.invoice_id == invoiceId
      );
      let tenant = booking
        ? tenants.find((tenant: tenantType) => tenant.id == booking.tenant_id)
        : billing
        ? tenants.find((tenant: any) => tenant.id == billing.tenant_id)
        : { first_name: "No", last_name: "Tenant" };

      let marked_by = findUser(i);
      if (!tenant) {
        tenant = { first_name: "No", last_name: "Tenant" };
      }
      let unit = booking
        ? units.find((unit: unitType) => unit.id == booking.unit_id)
        : { suite_name: "No Unit" };
      //when linking to back end: remember to switch the key to the unique invoice id from the backend

      Payments = (
        <tr
          key={i.toString()}
          className={
            altBg + " w-full  hover:bg-gray-100 h-12 border-b border-gray-400  "
          }
        >
          <td
            onClick={() => props.viewInvoice(invoiceId)}
            className="py-4 pl-5 text-purple-400 font-thin "
          >
            {invoiceId}
          </td>
          <td
            onClick={() => props.viewInvoice(invoiceId)}
            className="py-3 font-thin "
          >
            {unit.suite_name}
          </td>
          <td
            onClick={() => props.viewInvoice(invoiceId)}
            className="py-3  font-thin "
          >{`${tenant.first_name} ${tenant.last_name}`}</td>
          <td
            onClick={() => props.viewInvoice(invoiceId)}
            className="py-3  font-thin "
          >
            {marked_by}
          </td>
          <td onClick={() => props.viewInvoice(invoiceId)} className=" ">
            {sorted[i].paid}
          </td>
          <td onClick={() => props.viewInvoice(invoiceId)} className=" "></td>

          <td className="">
            <button
              onClick={() => {
                props.handleConfirm(invoiceId);
              }}
            >
              <MdCheckCircle className="text-3xl hover:text-green-500 active:text-green-500 text-gray-400 cursor-pointer"></MdCheckCircle>
            </button>
          </td>
          <td className=" text-right ">
            <button
              onClick={() => {
                props.handleDownload(invoiceId);
              }}
              className=" h-8 w-auto mr-3 px-2 border border-gray-300 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white "
            >
              Download
            </button>
          </td>
          <td className=" text-right ">
            <button
              onClick={() => {
                props.handleDecline(invoiceId);
              }}
              className=" h-8 w-auto mr-3 px-2 text-xs font-display text-red-600 hover:text-red-300 "
            >
              Decline
            </button>
          </td>
        </tr>
      );

      rows.push(Payments);
    }
    return rows;
  }

  if (props.consolidated) {
    return <>{consolidatedInvoiceTableCreation()}</>;
  } else {
    return <>{regularInvoiceTableCreation()}</>;
  }
}

function FinDashLinks(props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let currentPageStyle = "text-gray-400";
  let currentPage = props.linkEnd;
  // path.endsWith("Dashboard")

  if (path.includes("/Dashboard/" + currentPage)) {
    currentPageStyle = "border-b border-green-500 text-gray-800";
  } else if (path.endsWith("Dashboard") && props.text == "Overview") {
    currentPageStyle = "border-b-2 border-green-500 text-gray-800";
  }

  return (
    <div className={currentPageStyle + " mr-5 "}>
      <Link to={`${path}/${props.linkEnd}`} className="whitespace-nowrap">
        {props.text}
      </Link>
    </div>
  );
}
